import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import fr_FR from "./app/i18n/fr_FR/main.json";
import businessLayer_fr_FR from "./app/i18n/fr_FR/business-layer.json";
import navigation_fr_FR from "./app/i18n/fr_FR/navigation.json";
import calendar_fr_FR from "./app/i18n/fr_FR/calendar.json";
import en_CA from "./app/i18n/en_CA/main.json";
import businessLayer_en_CA from "./app/i18n/en_CA/business-layer.json";
import navigation_en_CA from "./app/i18n/en_CA/navigation.json";
import calendar_en_CA from "./app/i18n/en_CA/calendar.json";

const resources = {
  "fr-CA": {
    translation: fr_FR,
    bussLayer: businessLayer_fr_FR,
    navigation: navigation_fr_FR,
    calendar: calendar_fr_FR
  },
  "en-CA": {
    translation: en_CA,
    bussLayer: businessLayer_en_CA,
    navigation: navigation_en_CA,
    calendar: calendar_en_CA
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV !== "production",
    resources,
    lng: "fr-CA",
    fallbackLng: "fr-CA",
    keySeparator: ".",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
